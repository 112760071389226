import React from "react";
import plant from "../../images/1610375066331.jpg";

const ThreeColumnSection = () => {
    return (
        <div id="Eco" className="container mx-auto max-w-[1300px]">
            <div className="container mx-auto px-4">
                <h3 class="block text-xl font-bold py-8 text-darkbrown sm:text-4xl md:text-3xl lg:text-3xl darkbrown:text-white">
                    100% eco-OK Commitment with environmental quality
                </h3>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                    {/* Text Column 1 */}
                    <div className="md:col-span-1 flex items-top md:justify-start sm:text-left">
                        <p className="text-lg">
                            <p>
                                In our pursuit of excellence, environmental care
                                plays a crucial role in the quality of our
                                product.
                            </p>
                            <p>
                                We implement every process and procedure with a
                                deep commitment to the environmental practices
                                that we pledge to uphold throughout the entire
                                coffee production cycle. Our commitment to being
                                100% Eco-OK includes several key practices:
                            </p>
                            <ul>
                                <li>
                                    Protecting rivers and all other water
                                    bodies.
                                </li>
                                <li>
                                    Establishing buffer zones around protected
                                    areas and watercourses.
                                </li>
                                <li>
                                    Applying special ecological care within
                                    these buffer zones, where hunting is
                                    strictly prohibited.
                                </li>
                                <li>
                                    Preserving native trees as part of our
                                    shading system, which consists of two or
                                    three layers for optimal coffee growth.
                                </li>
                                <li>
                                    Being aware of and protecting endangered
                                    species in the region through specific
                                    measures.
                                </li>
                                <li>
                                    Storing pesticides, herbicides, and other
                                    chemicals securely, away from human
                                    activities and safe from animals or
                                    unauthorized access.
                                </li>
                                <li>
                                    Ensuring the quality of both surface and
                                    groundwater remains untainted by chemical
                                    products like insecticides and herbicides.
                                </li>
                                <li>
                                    Practicing recycling in all forms possible,
                                    with organic materials reused as fertilizer
                                    rather than being discarded into water
                                    bodies.
                                </li>
                                <li>
                                    Conducting periodic water quality analyses
                                    to guarantee the purity of water on the
                                    plantation.
                                </li>
                            </ul>
                            <p>
                                These practices underscore our dedication to
                                environmental stewardship as an integral part of
                                producing high-quality coffee.
                            </p>
                        </p>
                    </div>

                    {/* Text Column 2 */}
                    <div className="md:col-span-1 flex items-top md:justify-start sm:text-left">
                        <p className="text-lg">
                            The plantation employs comprehensive erosion control
                            measures to protect the topsoil. This includes
                            utilizing contour farming techniques to minimize
                            erosion and establishing natural, living barriers.
                            The landscape is enriched by the consistent canopy
                            of natural and indigenous trees, ensuring that the
                            plantation benefits from their shade. To sustain
                            soil fertility and structure, a layer of organic
                            material is maintained across the coffee fields,
                            promoting a continuous supply of organic nutrients
                            to the plants. In areas prone to steep slopes, the
                            use of herbicides is avoided, favoring the
                            application of organic matter generated on the farm
                            and the integration of nitrogen-fixing plants to
                            maintain soil quality. Chemical fertilizers are
                            applied sparingly and only when absolutely necessary
                            to maintain the quality of the coffee. Regular soil
                            analysis is conducted to determine the soil's
                            condition and nutritional requirements.
                        </p>
                    </div>

                    {/* Image Column */}
                    <div className="md:col-span-1 lg:col-span-2 xl:col-span-1 xl:max-h-[500] items-center justify-center rounded-xl object-cover">
                        {/* Ensure you replace 'your-image-path.jpg' with the actual path to your image */}
                        <img
                            src={plant}
                            alt="Description"
                            className="max-w-full h-auto max-h-[700px] rounded-xl"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThreeColumnSection;
