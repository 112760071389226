import React from "react";
import photo from "../../images/IMG-20200903-WA0007.jpg";
import flower from "../../images/IMG_20200912_151016_911.jpg";
import beans from "../../images/beans-product-info.png";

const roasting = () => {
    return (
        <div
            id="Roasting"
            class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
        >
            <div class="relative p-6 md:p-16">
                <div class="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
                    <div class="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
                        <h2 class="text-2xl text-gray-800 font-bold sm:text-3xl dark:text-gray-200">
                            Roasting Advice
                        </h2>

                        <nav
                            class="grid gap-4 mt-5 md:mt-10"
                            aria-label="Tabs"
                            role="tablist"
                        >
                            <button
                                type="button"
                                class="hs-tab-active:bg-green hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 active"
                                id="tabs-with-card-item-1"
                                data-hs-tab="#tabs-with-card-1"
                                aria-controls="tabs-with-card-1"
                                role="tab"
                            >
                                <span class="flex">
                                    <span class="grow ms-6">
                                        <span class="block text-lg font-semibold hs-tab-active:text-brown text-gray-800 dark:hs-tab-active:text-brown dark:text-gray-200">
                                            #1
                                        </span>
                                        <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">
                                            Roasting specifications, featuring
                                            an Agtron E-20 test.
                                        </span>
                                    </span>
                                </span>
                            </button>

                            <button
                                type="button"
                                class="hs-tab-active:bg-green hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                id="tabs-with-card-item-2"
                                data-hs-tab="#tabs-with-card-2"
                                aria-controls="tabs-with-card-2"
                                role="tab"
                            >
                                <span class="flex">
                                    <span class="grow ms-6">
                                        <span class="block text-lg font-semibold hs-tab-active:text-brown text-gray-800 dark:hs-tab-active:text-dark dark:text-gray-200">
                                            #2
                                        </span>
                                        <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">
                                            Dark roast - Agtron score of 35
                                            (range 33-37) for a traditional,
                                            darker roast.
                                        </span>
                                    </span>
                                </span>
                            </button>

                            <button
                                type="button"
                                class="hs-tab-active:bg-green hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                id="tabs-with-card-item-3"
                                data-hs-tab="#tabs-with-card-3"
                                aria-controls="tabs-with-card-3"
                                role="tab"
                            >
                                <span class="flex">
                                    <span class="grow ms-6">
                                        <span class="block text-lg font-semibold hs-tab-active:text-brown text-gray-800 dark:hs-tab-active:text-brown dark:text-gray-200">
                                            #3
                                        </span>
                                        <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">
                                            Deep brown to dark brown color,
                                            approximately 50-75% into the second
                                            crack.
                                        </span>
                                    </span>
                                </span>
                            </button>
                        </nav>
                    </div>

                    <div class="lg:col-span-6">
                        <div class="relative">
                            <div>
                                <div
                                    id="tabs-with-card-1"
                                    role="tabpanel"
                                    aria-labelledby="tabs-with-card-item-1"
                                >
                                    <img
                                        class="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/[.2]"
                                        src={photo}
                                        alt="plant"
                                    />
                                </div>

                                <div
                                    id="tabs-with-card-2"
                                    class="hidden"
                                    role="tabpanel"
                                    aria-labelledby="tabs-with-card-item-2"
                                >
                                    <img
                                        class="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/[.2]"
                                        src={flower}
                                        alt="coffee flower"
                                    />
                                </div>

                                <div
                                    id="tabs-with-card-3"
                                    class="hidden"
                                    role="tabpanel"
                                    aria-labelledby="tabs-with-card-item-3"
                                >
                                    <img
                                        class="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/[.2]"
                                        src={beans}
                                        alt="coffee beans"
                                    />
                                </div>
                            </div>

                            <div class="hidden absolute top-0 end-0 translate-x-20 md:block lg:translate-x-20">
                                <svg
                                    class="w-16 h-auto text-green"
                                    width="121"
                                    height="135"
                                    viewBox="0 0 121 135"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164"
                                        stroke="currentColor"
                                        stroke-width="10"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5"
                                        stroke="currentColor"
                                        stroke-width="10"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874"
                                        stroke="currentColor"
                                        stroke-width="10"
                                        stroke-linecap="round"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="absolute inset-0 grid grid-cols-12 w-full h-full">
                    <div class="col-span-full lg:col-span-7 lg:col-start-6 bg-gray-100 w-full h-5/6 rounded-xl sm:h-3/4 lg:h-full dark:bg-white/[.075]"></div>
                </div>
            </div>
        </div>
    );
};

export default roasting;
