import React from "react";
import harvest from "../../images/harvest-process.jpg";
import map from "../../images/map-gua.jpg";

const farm = () => {
    return (
        <div id="Process" className="container mx-auto max-w-[1300px]">
            <div className="container mx-auto px-4">
                <h3 class="block text-xl font-bold py-8 text-darkbrown sm:text-4xl md:text-3xl lg:text-3xl darkbrown:text-white">
                    Harvest and Farm process
                </h3>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                    {/* Text Column 1 */}
                    <div className="md:col-span-1 flex items-top md:justify-start sm:text-left">
                        <p className="text-lg">
                            Due to the farm's altitude and climate, the harvest
                            occurs from December to March. Special care is given
                            to picking only the ripest seeds, identified by
                            their deep red to purple hue. After each day's
                            harvest, the coffee is manually sorted to discard
                            any unripe seeds that were inadvertently collected.
                            Finca San Esteban de Pravia operates its own wet
                            mill, or "beneficio húmedo," where the coffee
                            processing begins daily. Upon arrival, the coffee is
                            placed into a large water tank, or "sifón," for a
                            second round of selection based on density; the
                            heavier, high-quality seeds sink to the bottom and
                            are used in the next stage of processing, while the
                            lighter seeds are separated for a different process.
                        </p>
                    </div>

                    {/* Text Column 2 */}
                    <div className="md:col-span-1 flex items-top md:justify-start sm:text-left">
                        <p className="text-lg">
                            Following the initial selection, the seeds are moved
                            from the tank to the pulpers, mechanical devices
                            that remove the coffee cherry's outer pulp, leaving
                            the inner seed exposed. This seed then goes through
                            a rotary sorter, or "criba," to remove any remaining
                            pulp. Subsequently, the seeds are placed in tanks
                            for a fermentation period of 24 to 48 hours to break
                            down the mucilage, a sticky substance surrounding
                            the seed. After fermentation, the coffee is washed
                            several times before being spread out on drying
                            patios or "patios." Depending on the weather, sun
                            drying can take between 8 to 15 days. This process
                            results in parchment coffee, or "café pergamino."
                            The final step involves removing the parchment layer
                            in a hulling process, "trilla," yielding green
                            coffee beans ready for traditional export.
                        </p>
                    </div>
                    <div className="md:col-span-1 flex items-top md:justify-start sm:text-left">
                        <p className="text-lg">
                            Don Julián's Coffee has taken its commitment to
                            quality a step further by roasting its own coffee
                            using traditional methods, achieving the exceptional
                            quality of Café Don Julián. The coffee is packaged
                            in high-barrier bags to ensure its quality remains
                            intact for over two years. The excellence of Don
                            Julián's Coffee has been acknowledged by Anacafé
                            (the Guatemalan National Coffee Association) and
                            various other coffee organizations. We now offer
                            this outstanding coffee directly from the farm to
                            you, eliminating intermediaries to guarantee the
                            product's origin and quality.
                        </p>
                    </div>
                </div>
                <div class="group sm:flex rounded-xl my-4 dark:focus:outline-none justify-center dark:focus:ring-1 dark:focus:ring-gray-600">
                    <div class="flex-grow relative rounded-xl overflow-hidden w-full h-[300px] sm:w-[250px] max-w-[800px] sm:h-[350px]">
                        <img
                            class="w-full h-full absolute top-0 start-0 object-cover "
                            src={harvest}
                            alt="harvest"
                        />
                    </div>
                </div>
                <div class="group sm:flex rounded-xl my-4 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                    <div class="flex-grow relative rounded-xl overflow-hidden w-full h-[500px] sm:w-[250px] sm:h-[750px]">
                        <img
                            class="w-full h-full absolute top-0 start-0 object-cover"
                            src={map}
                            alt="guatamala map"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default farm;
