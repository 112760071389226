import React from "react";
import antoncoffeplanten from "../../images/anton-coffee-planten.jpg";
import coffeplant from "../../images/IMG_20200903_103534_607.jpg";

const Product = () => {
    return (
        // <!-- Card Blog -->
        <div
            id="Product"
            class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
        >
            {/* <!-- Grid --> */}
            <div class="grid lg:grid-cols-1 gap-6">
                {/* <!-- Card --> */}
                <div class="group sm:flex rounded-xl dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                    <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full h-[300px] sm:w-[350px] sm:h-[450px]">
                        <img
                            class="w-full h-full absolute top-0 start-0 object-cover"
                            src={antoncoffeplanten}
                            alt="imaged"
                        />
                    </div>

                    <div class="grow">
                        <div class="p-4 flex-grow flex-col h-full sm:p-6">
                            <h2 class="text-lg sm:text-2xl font-semibold text-gray-800  dark:text-gray-300 dark:group-hover:text-white">
                                Product Information
                            </h2>
                            <p class="mt-2 text-gray-600 dark:text-gray-400">
                                <ul>
                                    <li>
                                        <strong>Coffee Type:</strong> S.H.B.
                                        (Strictly Hard Bean)
                                    </li>
                                    <li>
                                        <strong>Soil Type:</strong> Argillaceous
                                    </li>
                                    <li>
                                        <strong>Region:</strong> Rainforest
                                        Cobán
                                    </li>
                                    <li>
                                        <strong>Preparation:</strong> Gourmet
                                        Ground and Roasted, available in
                                        American or Espresso styles.
                                    </li>
                                    <li>
                                        <strong>Description:</strong> Cobán's
                                        rainforest region provides a unique
                                        environment for coffee production.
                                    </li>
                                    <li>
                                        <strong>Altitude:</strong> 1,500 meters
                                        above sea level
                                    </li>
                                    <li>
                                        <strong>Coffee Growing Area:</strong>{" "}
                                        22.5 hectares
                                    </li>
                                    <li>
                                        <strong>Average Temperature:</strong>{" "}
                                        18.5°C
                                    </li>
                                    <li>
                                        <strong>Relative Humidity:</strong>{" "}
                                        87.2%
                                    </li>
                                    <li>
                                        <strong>Varieties:</strong> Arabica
                                        Coffee - Caturra & Bourbon, grown under
                                        tree shade.
                                    </li>
                                    <li>
                                        <strong>Wet Mill Process:</strong>{" "}
                                        Natural fermentation and washing with
                                        crystal-clear water
                                    </li>
                                    <li>
                                        <strong>Drying Method:</strong> Solar
                                        dryer
                                    </li>
                                    <li>
                                        <strong>Distributor:</strong> Ivagro,
                                        S.A.
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div class="group sm:flex rounded-xl dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                    <div class="flex-grow relative rounded-xl overflow-hidden w-full h-[200px] sm:w-[250px] sm:h-[350px]">
                        <img
                            class="w-full h-full absolute top-0 start-0 object-cover"
                            src={coffeplant}
                            alt="coffe plant"
                        />
                    </div>
                </div>
                {/* <!-- End Card --> */}
            </div>
            {/* <!-- End Grid --> */}
        </div>
    );
};
export default Product;
