import React from "react";
import antonLuis from "../../images/anton-luis.jpg";

const Hero = () => {
    return (
        <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
            <div class="grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                <div class="lg:col-span-3">
                    <h1 class="block text-3xl font-bold text-darkbrown sm:text-4xl md:text-5xl lg:text-6xl dark:text-white">
                        Don Julian's Coffee
                    </h1>
                    <div class="flex gap-x-5">
                        <div class="grow">
                            <h3 class="text-lg font-semibold text-brown dark:text-white">
                                is a fine specialty coffee from the mountains of
                                Guatemala.
                            </h3>
                            <p class="mt-1 text-gray-600 dark:text-gray-400">
                                We distribute this exceptional coffee across
                                Europe, shipping it directly from the farms in
                                Guatemala. Our SHB (Strictly Hard Bean) coffee
                                is stored in climate-controlled facilities in
                                the Netherlands to ensure freshness and maintain
                                quality. This strategic location allows us to
                                easily and directly supply our customers,
                                guaranteeing the coffee's quality, with cupping
                                scores reaching 85 or higher. We exclusively
                                supply to roasters and offer advice on roasting
                                techniques. The Guatemalan farms where our
                                coffee is sourced are 100% eco-certified,
                                demonstrating a commitment to environmental
                                quality. For pricing information, please contact
                                us via email.
                            </p>
                        </div>
                    </div>
                    <p class="mt-3 text-lg text-gray-800 dark:text-gray-400"></p>

                    <div class="mt-5 lg:mt-8 flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
                        <div class="w-full sm:w-auto">
                            <label for="hero-input" class="sr-only">
                                Mail
                            </label>
                        </div>
                        <a
                            class="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brown text-white hover:bg- disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            href="mailto:donjuliancoffee.europe@gmail.com"
                        >
                            Mail
                        </a>
                    </div>
                </div>

                <div class="lg:col-span-4 mt-10 lg:mt-0">
                    <img
                        class="w-full rounded-xl"
                        src={antonLuis}
                        alt="anton and luis"
                    />
                </div>
            </div>
        </div>
    );
};

export default Hero;
