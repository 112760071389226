import { useEffect } from "react";
import Nav from "./components/navbar/nav";
import Hero from "./components/hero/hero";
import Plants from "./components/roasting/roasting";
import "preline/preline";
import { useLocation } from "react-router-dom";
import Footer from "./components/footer/footer";
import Clients from "./components/clients/clients";
import Contact from "./components/contact/contact";
import Product from "./components/product/product";
import Carousel from "./components/carosel/carosel";
import ThreeColumnSection from "./components/eco/eco";
import DonJulian from "./components/donjulian/donjulian";
import Farm from "./components/farm/farm";

function App() {
    const location = useLocation();

    useEffect(() => {
        window.HSStaticMethods && window.HSStaticMethods.autoInit();
    }, [location]);

    return (
        <div className="app">
            <Nav />
            <div class="relative py-14 bg-gradient-to-bl from-green via-transparent dark:from-green dark:via-transparent">
                <div class="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    <Hero />
                </div>
            </div>
            <div className="container mx-auto">
                <Product />
                <Plants />
                <Carousel />
                <ThreeColumnSection />
                <DonJulian />
                <Farm />
                <Clients />
                <Contact />
            </div>
            <Footer />
        </div>
    );
}

export default App;
