import React from "react";
import farm from "../../images/farm.jpg";

const DonJulian = () => {
    return (
        <div className="container mx-auto max-w-[1300px]">
            <div className="container mx-auto px-4">
                <h3 class="block text-xl font-bold py-8 text-darkbrown sm:text-4xl md:text-3xl lg:text-3xl darkbrown:text-white">
                    Don Julians Coffee
                </h3>
                {/* Text Column 1 */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Text Column */}
                    <div className="flex items-center justify-center md:justify-start text-center md:text-left">
                        <p className="text-lg">
                            Finca San Esteban de Pravia has a very long coffee
                            tradition. The first family plantations were located
                            in Lake Atitlán's surroundings, in Finca San Julián
                            since 1860. This coffee tradition went on and has
                            been improved over time and generations achieving an
                            excellent symbiosis between the excellent production
                            tradition and the most modern processing techniques.
                            Don Julián's tradition is still alive in the coffee
                            plantation at Finca San Esteban de Pravia, an estate
                            located in the Central Plateau of the Sierra Madre
                            mountain range at 5000 ft. It is completely
                            surrounded by two clear water rivers and the coffee
                            plantation occupies more than 90% of the farm
                            extension. Each and every plant in the plantation is
                            carefully taken care of from the very beginning. The
                            new plant seeds are selected among the best seeds of
                            the plantation. This seeds are rooted then grown in
                            the almácigo under cushin tree shade. When the
                            plants are almost a year old, they are transported
                            into the fields where shade is already grown to
                            minimize the plant stress in this movement and in
                            the future plantation. Two years after the coffee
                            plants are taken into the plantation, the first
                            production is obtained; the production then
                            continues once a year.
                        </p>
                    </div>

                    {/* Image Column */}
                    <div className="flex items-center justify-center">
                        {/* Replace 'your-image-path.jpg' with the path to your image */}
                        <img
                            src={farm}
                            alt="Description"
                            className="max-w-full h-auto max-h-[700px] rounded-xl"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DonJulian;
